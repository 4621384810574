import React, { useEffect } from 'react';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
// import Contact from './pages/Contact';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import ProjectDetails from './pages/ProjectDetails';
import ServiceDetails from './pages/ServiceDetails';
import Layout from './components/layout';


const Contact = React.lazy(() => import('./pages/Contact'));

function App() {

  
  useEffect(() => {
    const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (userPrefersDark) {
      console.log("Dark mode detected")
      document.documentElement.classList.add('dark');
    } else {
      console.log("light mode detected")
      document.documentElement.classList.remove('dark');
    }
  }, []);

  return (
    <Router>
      <div>
      
        <Navbar />
        
     
        <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service-details" element={<ServiceDetails />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/project-details" element={<ProjectDetails />} />
          </Routes>
          </Suspense>
        </Layout>
        
      
        <Footer />
      </div>
    </Router>
  );
}

export default App;
