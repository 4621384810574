import React from 'react';

const ProjectDetails = () => {
  return (
    <>
      
      <section
  className="text-white py-24 text-center"
  style={{
    backgroundImage: "url('/Images/45184484_9145838.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }}
>
  <div className="container mx-auto">
    <div className="inline-flex items-center bg-white text-gray-700 dark:bg-gray-800 dark:text-gray-200 rounded-full px-4 py-2 mb-4">
      <span className="text-blue-500 mr-2">•</span>
      <span>PROJECT DETAILS</span>
      <span className="text-blue-500 ml-2">•</span>
    </div>
    <h1 className="text-5xl font-bold mb-4">Project Title 1</h1>
    <p className="text-sm mt-2">Home → Projects</p>

    <div className="mt-8">
      <p className="text-lg font-light max-w-3xl mx-auto">
        Here you can include a detailed description of the project. This can cover the objectives, technologies used, challenges faced, and outcomes achieved. Engaging descriptions can provide visitors with a clear understanding of the project.
      </p>
      
      <div className="mt-6 flex justify-center space-x-4">
        <button className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition duration-300">
          View More
        </button>
        <button className="bg-gray-300 dark:bg-gray-700 text-gray-900 dark:text-gray-300 py-2 px-4 rounded-full hover:bg-gray-400 dark:hover:bg-gray-600 transition duration-300">
          Back to Projects
        </button>
      </div>
    </div>
  </div>
</section>



      <section className="py-20 bg-white dark:bg-gray-900">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-20 p-4">
    <div className="relative">
      <div className="absolute left-2 top-0 h-[75%] border-l-2 border-dotted border-gray-300 dark:border-gray-600 ml-3"></div>

      {[
        {
          title: "Project Overview",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        },
        {
          title: "Challenge",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        },
        {
          title: "Solutions",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        },
        {
          title: "Results",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        },
      ].map((item, index) => (
        <div className="relative flex items-start space-x-4 mb-10" key={index}>
          <span className="w-10 h-10 rounded-full bg-blue-800 flex-shrink-0"></span>
          <div>
            <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 mb-4">{item.title}</h3>
            <p className="text-gray-600 dark:text-gray-400">{item.content}</p>
          </div>
        </div>
      ))}
    </div>

   
    <div className="bg-gray-50 dark:bg-gray-800 w-full md:w-[400px] h-[auto] rounded-lg p-8 md:p-20">
      {[
        { label: "Client", value: "Lorem Ipsum" },
        { label: "Company", value: "Lorem Ipsum" },
        { label: "Project Type", value: "Lorem Ipsum" },
        { label: "Duration", value: "Lorem Ipsum" },
        { label: "Technologies Used", value: "Lorem Ipsum" },
      ].map((item, index) => (
        <div className="mb-10" key={index}>
          <p className="text-sm text-gray-500 dark:text-gray-400 text-center">{item.label}</p>
          <p className="text-lg font-bold text-gray-900 dark:text-gray-200 text-center">{item.value}</p>
        </div>
      ))}
    </div>
  </div>
</section>


      

      <section className="bg-white dark:bg-gray-900">
  <div className="container mx-auto p-16 grid grid-cols-1 md:grid-cols-2 gap-6">
   
    <div className="rounded-lg overflow-hidden shadow-lg">
      <img 
        src="/Images/Image Placeholder (11).png" 
        alt="Description of the first" 
        className="w-full h-auto" 
      />
    </div>


    <div className="rounded-lg overflow-hidden shadow-lg">
      <img 
        src="/Images/Image Placeholder (13).png" 
        alt="Description of the second" 
        className="w-full h-auto" 
      />
    </div>

    
    <div className="col-span-2 rounded-lg overflow-hidden shadow-lg">
      <img 
        src="/Images/Image Placeholder (10).png" 
        alt="Description of the third " 
        className="w-full h-auto" 
      />
    </div>
  </div>
</section>


     

      <section className="py-16 bg-white dark:bg-gray-900">
  <div className="container mx-auto p-12">
    <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-200 mb-8">Related Projects</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      
      <div className="bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg p-4">
        <img
          src="/Images/Image Placeholder (11).png"
          alt="Description of Project Title 1"
          className="w-full h-[200px] rounded-lg mb-4 object-cover"
        />
        <h3 className="text-lg font-bold text-gray-900 dark:text-gray-200 mb-2 text-left">Project Title 1</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4 text-left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="flex justify-start">
          <button className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300">
            View Project →
          </button>
        </div>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg p-4">
        <img
          src="/Images/Image Placeholder (13).png"
          alt="Description of Project Title 2"
          className="w-full h-[200px] rounded-lg mb-4 object-cover"
        />
        <h3 className="text-lg font-bold text-gray-900 dark:text-gray-200 mb-2 text-left">Project Title 2</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4 text-left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="flex justify-start">
          <button className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300">
            View Project →
          </button>
        </div>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg p-4">
        <img
          src="/Images/Image Placeholder (14).png"
          alt="Description of Project Title 3"
          className="w-full h-[200px] rounded-lg mb-4 object-cover"
        />
        <h3 className="text-lg font-bold text-gray-900 dark:text-gray-200 mb-2 text-left">Project Title 3</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4 text-left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="flex justify-start">
          <button className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300">
            View Project →
          </button>
        </div>
      </div>
    </div>
  </div>
</section>


      

      <section className="py-16 bg-gradient-to-b from-white to-blue-100 dark:from-gray-900 dark:to-gray-800 relative mt-80">
  <div className="container mx-auto text-center">
    <img
      src="/Images/emojistar 1.png"
      alt="3D Star"
      className="absolute top-2.5 transform -translate-y-1/2 w-[200px] h-[200px] lg:w-[300px] lg:h-[300px] left-[-4px] z-10"
    />

    <p className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-20 relative" style={{ top: "-60px" }}>
      Let’s create the next <span className="text-purple-600">big thing</span> together!
    </p>

    <div className="flex justify-center space-x-6 mb-10">
      <button className="bg-blue-500 text-white py-3 px-8 w-40 rounded-full hover:bg-blue-600 transition duration-300">Get started</button>
      <button className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 py-3 px-8 w-40 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300">
        Talk to sales
      </button>
    </div>

    <img
      src="/Images/helix2 1.png"
      alt="3D Spiral"
      className="absolute top-1/2 right-0 transform -translate-y-1/2 w-[200px] h-[200px] lg:w-[300px] lg:h-[300px] z-10"
    />
  </div>
</section>

    </>
  );
};

export default ProjectDetails;
