
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="shadow-md p-4 w-full bg-white dark:bg-gray-900">
      <div className="container mx-auto flex justify-between items-center">
    
        <div>
          <Link to="/">
            <img
              src="/Images/cognitosoft__1_-removebg-preview.png"
              alt="Cognito Soft Logo"
              className="w-[140px] h-16 cursor-pointer"
            />
          </Link>
        </div>


        <div className="lg:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-900 dark:text-white focus:outline-none"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        <div className={`space-x-8 lg:flex items-center ${isMenuOpen ? 'block' : 'hidden'} w-full lg:w-auto lg:relative absolute top-full left-0 lg:top-auto bg-white dark:bg-gray-900 lg:bg-transparent lg:p-0 p-4`}>
          <Link to="/" className="block lg:inline hover:text-blue-500 text-gray-900 dark:text-white my-2 lg:my-0">
            Home
          </Link>
          <Link to="/about" className="block lg:inline hover:text-blue-500 text-gray-900 dark:text-white my-2 lg:my-0">
            About
          </Link>
          <Link to="/portfolio" className="block lg:inline hover:text-blue-500 text-gray-900 dark:text-white my-2 lg:my-0">
            Portfolio
          </Link>
          <div className="relative group inline-block">
            <button className="hover:text-blue-500 text-gray-900 dark:text-white">
              Services
            </button>
            <div className="absolute hidden group-hover:block shadow-md rounded bg-white dark:bg-gray-800">
              <Link to="/services" className="block px-4 py-2 text-sm text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700">
                All Services
              </Link>
            </div>
          </div>
          <Link to="/contact" className="block lg:inline hover:text-blue-500 text-gray-900 dark:text-white my-2 lg:my-0">
            Contact
          </Link>
        </div>

        <div className="hidden lg:flex items-left space-x-4">
          <svg className="w-8 h-8 text-gray-900 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 10 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3c.132 0 .26.03.384.09a9.75 9.75 0 101.13 15.036A7.5 7.5 0 0112 3z" />
          </svg>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
