import React from "react";

const Portfolio = () => {
  return (
    <>
      <section
  className="text-white py-24 text-center"
  style={{
    backgroundImage: "url('/Images/45184484_9145838.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }}
>
  <div className="container mx-auto">
    <div className="inline-flex items-center bg-white text-gray-700 dark:bg-gray-800 dark:text-gray-200 rounded-full px-4 py-2 mb-4 shadow-lg">
      <span className="text-blue-500 mr-2">•</span>
      <span>PROJECTS</span>
      <span className="text-blue-500 ml-2">•</span>
    </div>
    <h1 className="text-5xl font-bold">Our Completed Projects</h1>
    <p className="text-sm mt-2">Home → Services</p>
  </div>
</section>

<section className="py-16 bg-white dark:bg-gray-900">
  <div className="container mx-auto text-center">
    <div className="mb-8">
      <ul className="flex justify-center space-x-8">
        <li className="text-lg font-bold text-gray-900 dark:text-gray-200 border-b-2 border-black dark:border-white cursor-pointer">
          First tab
        </li>
        <li className="text-lg text-gray-500 dark:text-gray-400 cursor-pointer hover:text-gray-900 dark:hover:text-gray-200">
          Second tab
        </li>
        <li className="text-lg text-gray-500 dark:text-gray-400 cursor-pointer hover:text-gray-900 dark:hover:text-gray-200">
          Third tab
        </li>
        <li className="text-lg text-gray-500 dark:text-gray-400 cursor-pointer hover:text-gray-900 dark:hover:text-gray-200">
          Fourth tab
        </li>
      </ul>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg p-4">
          <img
            src={`/Images/Image Placeholder (${index + 11}).png`}
            alt={`Project Title ${index + 1}`}
            className="w-full h-[200px] rounded-lg mb-4"
          />
          <h3 className="text-lg font-bold text-gray-900 dark:text-gray-200 mb-2 text-left">
            Project Title {index + 1}
          </h3>
          <p className="text-gray-600 dark:text-gray-400 mb-4 text-left">
            Lorem ipsum dolor sit amet consectetur tur adipiscing elit semper dalarac lacus vel facilisis volutpat est velitlom.
          </p>

          <div className="flex justify-start">
            <button className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition duration-300">
              View Project →
            </button>
          </div>
        </div>
      ))}
    </div>

    <div className="mt-8 flex justify-center space-x-4">
      <button className="w-12 h-12 flex justify-center items-center border border-gray-300 dark:border-gray-600 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-6 h-6 text-gray-500 dark:text-gray-400"
        >
          <path d="M19 12H5M12 19l-7-7 7-7" />
        </svg>
      </button>
      <div className="flex space-x-4">
        {Array.from({ length: 3 }).map((_, index) => (
          <button key={index} className={`w-12 h-12 ${index === 0 ? "bg-blue-600 text-white" : "bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600"} rounded`}>
            {index + 1}
          </button>
        ))}
      </div>
      <button className="w-12 h-12 flex justify-center items-center border border-gray-300 dark:border-gray-600 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-6 h-6 text-gray-500 dark:text-gray-400"
        >
          <path d="M5 12h14M12 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  </div>
</section>

    </>
  );
};

export default Portfolio;
