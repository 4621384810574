import React from "react";

const Services = () => {
  return (
    <>
    
      <section
  className="text-white py-24 text-center relative"
  style={{
    backgroundImage: "url('/Images/45184484_9145838.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }}
>
  <div className="container mx-auto relative z-10">
    <div className="inline-flex items-center bg-white text-gray-700 dark:bg-gray-800 dark:text-white rounded-full px-4 py-2 mb-4">
      <span className="text-blue-500 mr-2">•</span>
      <span>SERVICES</span>
      <span className="text-blue-500 ml-2">•</span>
    </div>
    <h1 className="text-5xl font-bold dark:text-white mb-2">
      Get to Know about Cognito Softy
    </h1>
    <p className="text-sm mt-2 text-gray-300 dark:text-gray-400">
      Home → Services
    </p>
  </div>
  <div className="absolute left-[-100px] bottom-[-180px] transform rotate-[-5deg] z-10">
    <img
      src="/Images/pyramid 1 (1).png"
      alt="Pyramid"
      className="w-[100px] h-auto"
    />
  </div>
</section>


      <section className="py-24 bg-white dark:bg-gray-900">
  <div className="container mx-auto text-center">
    <h2 className="text-4xl font-bold text-gray-800 dark:text-white mb-8">
      Browse our <span className="text-purple-600">set of features</span>
    </h2>
    <p className="text-gray-600 dark:text-gray-400 mb-12 max-w-2xl mx-auto">
      Lorem ipsum dolor sit amet consectetur adipiscing elit semper dalar
      elementum tempus hac tellus libero accumsan.
    </p>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
      {[
        { imgSrc: "/Images/image 4.png", title: "Web Development" },
        { imgSrc: "/Images/image 2.png", title: "Cloud Services" },
        { imgSrc: "/Images/image 3.png", title: "Amazon Services" },
        { imgSrc: "/Images/image 12.png", title: "AI Platform Integrations" },
        { imgSrc: "/Images/image 11.png", title: "API Integration" },
        { imgSrc: "/Images/image 13.png", title: "AI CSR" }
      ].map((feature, index) => (
        <div key={index} className="bg-gray-100 dark:bg-gray-800 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 text-left">
          <img src={feature.imgSrc} alt={feature.title} className="w-12 h-12 mb-4" />
          <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">{feature.title}</h3>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Lorem ipsum dolor sit amet consectetur tur adipiscing elit semper dalarac lacus vel facilisis volutpat est velitorn.
          </p>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300">
            Learn more →
          </button>
        </div>
      ))}
    </div>
  </div>
</section>


     
      <section className="py-12 bg-white dark:bg-gray-900">
  <div className="container mx-auto">
    <div className="text-center mb-12">
      <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
        What our <span className="text-purple-600">clients say</span>
      </h2>
      <p className="text-gray-600 dark:text-gray-400 mt-2">
        Lorem ipsum dolor sit amet consectetur adipiscing elit semper dalar
        elementum tempus hac tellus libero
      </p>
    </div>

    <div className="relative bg-gray-100 dark:bg-gray-800 mx-auto max-w-6xl rounded-lg shadow-lg p-6 flex flex-col md:flex-row">
      <div className="w-full md:w-1/2">
        <img
          src="/Images/Image Placeholder (8).png"
          alt="Client's testimonial"
          className="rounded-lg shadow-lg mb-4 md:mb-0"
        />
      </div>

      <div className="w-full md:w-1/2 pl-0 md:pl-8">
        <blockquote className="text-xl font-semibold text-gray-800 dark:text-white">
          “An amazing service”
        </blockquote>
        <p className="text-gray-600 dark:text-gray-400 mt-4">
          Lorem ipsum dolor sit amet consectetur adipiscing elit ultrices
          scelerisque id sed interdum lacus tellus in mi orci, risus nisi
          lobortis phasellus.
        </p>
        <p className="mt-6 font-bold text-gray-800 dark:text-white">
          John Carter
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Designer at BRIX Templates
        </p>
      </div>
    </div>

    <div className="flex justify-center mt-8">
      <span className="w-3 h-3 bg-blue-500 rounded-full mx-1 cursor-pointer"></span>
      <span className="w-3 h-3 bg-gray-300 dark:bg-gray-600 rounded-full mx-1 cursor-pointer"></span>
      <span className="w-3 h-3 bg-gray-300 dark:bg-gray-600 rounded-full mx-1 cursor-pointer"></span>
    </div>
  </div>
</section>



      <section className="py-16 bg-gradient-to-b from-white to-blue-100 dark:from-gray-900 dark:to-gray-800">
  <div className="container mx-auto max-w-full sm:max-w-3xl px-4">
    <h2 className="text-3xl font-bold text-gray-800 dark:text-white text-center mb-6">
      Frequently Asked Questions
    </h2>
    <p className="text-gray-600 dark:text-gray-400 text-center mb-12">
      Lorem ipsum dolor sit amet consectetur adipiscing elit aenean id
      volutpat imperdiet quis at pellentesque nunc commodo nunc purus
      pulvinar nisi fusce.
    </p>
    <div className="space-y-4">
      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 md:p-12">
        <div className="flex justify-between items-center cursor-pointer">
          <h3 className="text-lg font-bold text-gray-900 dark:text-white">
            What is Webflow and why is it the best website builder?
          </h3>
          <span className="text-gray-500 dark:text-gray-400">▼</span>
        </div>
        <p className="text-gray-600 dark:text-gray-400 mt-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit id
          venenatis pretium risus euismod dictum egestas orci netus
          feugiat ut egestas ut sagittis tincidunt phasellus elit etiam
          cursus orci in. Id sed montes.
        </p>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 md:p-12 cursor-pointer">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold text-gray-900 dark:text-white">
            When did Webflow was founded?
          </h3>
          <span className="text-gray-500 dark:text-gray-400">›</span>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 md:p-12 cursor-pointer">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold text-gray-900 dark:text-white">
            How do you clone a Webflow Template?
          </h3>
          <span className="text-gray-500 dark:text-gray-400">›</span>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 md:p-12 cursor-pointer">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold text-gray-900 dark:text-white">
            Why is BRIX Templates the best Webflow agency?
          </h3>
          <span className="text-gray-500 dark:text-gray-400">›</span>
        </div>
      </div>
    </div>
  </div>
</section>


    
      <section className="py-16 bg-gradient-to-b from-white to-blue-100 dark:from-gray-900 dark:to-gray-800 relative mt-80">
  <div className="container mx-auto text-center relative">
    <img
      src="/Images/emojistar 1.png"
      alt="3D Star"
      className="absolute top-10 transform -translate-y-1/2 w-[150px] h-[150px] lg:w-[250px] lg:h-[250px] left-[-4px]"
    />

    <p className="text-4xl font-bold text-gray-800 dark:text-white mb-20 relative">
      Let’s create the next <span className="text-purple-600">big thing</span> together!
    </p>

    <div className="flex justify-center space-x-6 flex-wrap">
      <button className="bg-blue-500 text-white py-3 px-8 w-40 rounded-full hover:bg-blue-600">
        Get started
      </button>
      <button className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white py-3 px-8 w-40 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600">
        Talk to sales
      </button>
    </div>

    <img
      src="/Images/helix2 1.png"
      alt="3D Spiral"
      className="absolute top-1/2 right-0 transform -translate-y-1/2 w-[150px] h-[150px] lg:w-[250px] lg:h-[250px]"
    />
  </div>
</section>

    </>
  );
};

export default Services;
