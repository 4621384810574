import React from "react";

const About = () => {
  return (
    <>
   
      
      <section
  className="text-white py-24 text-center"
  style={{
    backgroundImage: "url('/Images/45184484_9145838.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }}
>
  <div className="container mx-auto px-6 lg:px-12">
    <div className="inline-flex items-center bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200 rounded-full px-4 py-2 mb-4">
      <span className="text-blue-500 mr-2">•</span>
      <span className="text-sm md:text-base">ABOUT US</span>
      <span className="text-blue-500 ml-2">•</span>
    </div>
    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold dark:text-white"> 
    </h1>
    <p className="text-xs md:text-sm mt-2 dark:text-gray-400"> 
      Home → About Us
    </p>
  </div>
</section>



      <section className="py-16 bg-gradient-to-b from-white to-indigo-50 dark:from-gray-900 dark:to-gray-800 px-6 lg:px-12">
  <div className="container mx-auto flex flex-col lg:flex-row items-center">
    <div className="lg:w-1/2 text-left">
      <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 rounded-full px-4 py-2 mb-4 text-gray-700 dark:text-gray-200">
        <span className="text-blue-500 mr-2">•</span>
        <span>Our Values</span>
        <span className="text-blue-500 ml-2">•</span>
      </div>

      <h2 className="text-3xl md:text-4xl font-bold text-gray-800 dark:text-gray-200 mb-4">
        Who <span className="text-purple-600">we Are</span>
      </h2>
      <p className="text-gray-600 dark:text-gray-400 max-w-md mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam.
      </p>

      <div className="relative flex items-center justify-between max-w-md mb-8">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-4/5 border-t border-gray-300 dark:border-gray-600"></div>
        </div>

        {['Innovation', 'Collaboration', 'Result Oriented', 'Sustainability'].map((value, index) => (
          <div key={index} className="relative z-10 flex flex-col items-center">
            <span className="block w-4 h-4 bg-blue-500 rounded-full absolute top-[24px]"></span>
            <p className="mt-10 text-gray-700 dark:text-gray-300">{value}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4">
        <button className="bg-blue-500 text-white py-3 px-6 rounded-full hover:bg-blue-600 mb-2 sm:mb-0">
          Get Started
        </button>
        <button className="bg-white dark:bg-gray-700 dark:text-gray-200 text-gray-700 py-3 px-6 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600">
          Talk to Us
        </button>
      </div>
    </div>

    <div className="lg:w-1/2 flex justify-center lg:justify-end mt-8 lg:mt-0">
      <div className="relative w-full max-w-[473px] h-[500px] bg-white dark:bg-gray-800 rounded-[20px] inset-[1px] shadow-md">
        <img
          src="/Images/Image Placeholder (15).png"
          alt="Project Title"
          className="w-full h-full rounded-lg mb-4"
        />
        <div className="absolute top-[50%] left-[-80px] w-[231px] h-[283px] bg-light-grayish-lavender rounded-[80px]">
          <img
            src="/Images/Container (7).png"
            alt="Project Title"
            className="w-full h-full rounded-lg mb-4"
          />
        </div>
      </div>
    </div>
  </div>
</section>



      <section className="py-16 bg-white dark:bg-gray-900 relative px-6 lg:px-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-12">
    <div className="text-left">
      <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-full px-4 py-2 mb-4">
        <span className="text-blue-500 mr-2">•</span>
        <span>Why we are better</span>
        <span className="text-blue-500 ml-2">•</span>
      </div>
      <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
        Experience excellence with our{" "}
        <span className="text-purple-600">expert team</span>
      </h2>
      <p className="text-gray-600 dark:text-gray-400 mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam, quis nostrud exercitation ullamco laboris
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
        reprehenderit.
      </p>
    </div>

    <div>
      <img
        src="/Images/Image Placeholder (4).png"
        alt="Expert Team"
        className="w-full rounded-lg shadow-lg"
      />
    </div>
  </div>

  <div className="absolute right-1 bottom-[-120px] z-10">
    <img
      src="/Images/pyramid 1.png"
      alt="Pyramid"
      className="w-[100px] h-auto"
    />
  </div>
</section>


     

      <section className="py-16 bg-white dark:bg-gray-900 px-6 lg:px-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-12">
    <div className="flex justify-center mb-8 md:mb-0">
      <img
        src="/Images/Image Placeholder (5).png"
        alt="Customized Solutions"
        className="w-full rounded-lg shadow-lg"
      />
    </div>

    <div className="text-left">
      <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
        Customized <span className="text-purple-600">solutions</span> for
        your <span className="text-purple-600">unique needs</span>
      </h2>
      <p className="text-gray-600 dark:text-gray-400">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam, quis nostrud exercitation ullamco laboris
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
        reprehenderit.
      </p>
    </div>
  </div>
</section>

  
     
      <section className="relative py-16 bg-white dark:bg-gray-900 px-6 lg:px-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-12">
    <div className="text-left">
      <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
        Transparent{" "}
        <span className="text-purple-600">communication</span> and{" "}
        <span className="text-purple-600">collaboration</span>
      </h2>
      <p className="text-gray-600 dark:text-gray-400">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam, quis nostrud exercitation ullamco laboris
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
        reprehenderit.
      </p>
    </div>

    <div className="flex justify-center">
      <img
        src="/Images/Image Placeholder (6).png"
        alt="Team Collaboration"
        className="w-full max-w-md rounded-lg shadow-lg"
      />
    </div>
  </div>

  <div className="absolute -bottom-20 left-0 z-10">
    <img
      src="/Images/helix2 1 (1).png"
      alt="Blue Helix"
      className="w-[100px] h-auto"
    />
  </div>
</section>




      <section className="py-16 bg-white dark:bg-gray-900 px-6 lg:px-12">
  <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center">
    <div className="mb-8 lg:mb-0">
      <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
        Meet our <span className="text-purple-600">amazing team</span>
      </h2>
      <p className="text-gray-600 dark:text-gray-400 max-w-lg">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam, quis.
      </p>
    </div>

    <div className="flex space-x-4">
      <button className="w-12 h-12 flex justify-center items-center border border-gray-300 dark:border-gray-700 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 text-gray-500 dark:text-gray-300"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button className="w-12 h-12 flex justify-center items-center bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-200">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  </div>
</section>


      <section className="py-16 bg-white dark:bg-gray-900 px-6 lg:px-12">
  <div className="container mx-auto">
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
    
      {["Sophie Moore", "Jane Doe", "John Smith", "Emily Davis"].map((name, index) => (
        <div key={index} className="bg-gray-200 dark:bg-gray-700 rounded-lg shadow-lg text-center relative">
          <img
            src={`/Images/Image Placeholder (${index + 5}).png`} 
            alt={name}
            className="w-full h-[550px] object-cover rounded-t-lg"
          />
          <div className="absolute inset-x-0 bottom-16 text-center text-white dark:text-gray-300">
            <h3 className="text-xl font-bold">{name}</h3>
            <p className="text-gray-300 dark:text-gray-400">CTO & Co-Founder</p>
          </div>
          <div className="absolute top-[500px] left-8 flex space-x-4">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
              aria-label={`Instagram profile of ${name}`}
            >
              <img
                src="/Images/instagram (1).png"
                alt="Instagram"
                className="w-8 h-8"
              />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
              aria-label={`LinkedIn profile of ${name}`}
            >
              <img
                src="/Images/linkedin (1).png"
                alt="LinkedIn"
                className="w-8 h-8"
              />
            </a>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>


      
    
      <section className="py-16 bg-white dark:bg-gray-900 px-6 lg:px-12">
  <div className="container mx-auto">
    <div className="relative text-white max-w-[1200px] h-[400px] mx-auto bg-blue-800 rounded-lg shadow-lg overflow-hidden">
      <img
        src="/Images/Container (4).png"
        alt="Background"
        className="absolute w-full h-full rounded-lg opacity-50"
        style={{ objectFit: "cover", top: "0", left: "0" }}
      />
      <blockquote className="relative text-center italic text-4xl font-light z-10 px-4 sm:px-20 top-[70px] mt-14">
        «Our mission is to empower businesses and individuals to thrive in
        the digital age. We are dedicated to delivering innovative IT
        solutions and services that drive efficiency, enhance
        productivity, and maximize growth.»
      </blockquote>

      <div className="relative flex items-center justify-center top-[90px] mt-8 z-10">
        <img
          src="/Images/Ellipse 433.png"
          alt="Emily Johnson"
          className="w-12 h-12 rounded-full object-cover border-2 border-white"
        />
        <div className="ml-4 text-white">
          <p className="font-bold">Emily Johnson</p>
          <p className="text-sm">CEO</p>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default About;
