import React from 'react';

const Layout = ({ children }) => {
  return (
    <div className="px-4 py-4 md:px-8 md:py-8 lg:px-16 lg:py-12 xl:px-24 xl:py-16 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-300">
      {children}
    </div>
  );
};

export default Layout;
