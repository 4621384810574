import React from "react";

const ServiceDetails = () => {
  return (
    <>
      <section
        className="text-white py-24 text-center"
        style={{
          backgroundImage: "url('/Images/45184484_9145838.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto">
          <div className="inline-flex items-center bg-white text-gray-700 dark:bg-gray-800 dark:text-gray-200 rounded-full px-4 py-2 mb-4">
            <span className="text-blue-500 mr-2">•</span>
            <span>SERVICES DETAILS</span>
            <span className="text-blue-500 ml-2">•</span>
          </div>
          <h1 className="text-5xl font-bold">App Development</h1>
          <p className="text-sm mt-2">Home → Services</p>
        </div>
      </section>

      <section className="py-28 px-20 bg-white dark:bg-gray-900">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-32">
          <div className="text-left mb-32">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-200 mb-6">
              App Development
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit.
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <ul>
                <li className="flex items-center space-x-3">
                  <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-700 dark:text-gray-300 mb-4">
                    Improved Scalability
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-700 dark:text-gray-300 mb-4">
                    Enhanced Security
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-700 dark:text-gray-300 mb-4">
                    Lorem ipsum dolor
                  </span>
                </li>
              </ul>

              <ul>
                <li className="flex items-center space-x-3">
                  <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-700 dark:text-gray-300 mb-4">
                    Best User Experience
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-700 dark:text-gray-300 mb-4">
                    Lorem ipsum dolor
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-700 dark:text-gray-300 mb-4">
                    Lorem ipsum dolor
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <img
              src="/Images/Image Placeholder (16).png"
              alt="App Development"
              className="w-[482px] h-[420px] rounded-lg shadow-lg"
            />
          </div>
        </div>
      </section>

      <section className="py-12 px-20 bg-gradient-to-b from-white to-indigo-50 dark:from-gray-900 dark:to-gray-800">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
          <div className="text-left">
            <div className="flex items-center space-x-4 mb-4">
              <div className="w-14 h-14 bg-purple-600 rounded-full flex justify-center items-center">
                <img
                  src="/Images/image 14.png"
                  alt="Technology Icon"
                  className="w-14 h-14"
                />
              </div>

              <div className="text-2xl font-bold text-gray-900 dark:text-gray-200">
                <p>Technologies</p>
                <p>we use</p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <div className="flex items-center mb-2">
                <h3 className="text-purple-600 text-lg font-bold">01</h3>
              </div>
              <div className="flex-grow border-b-2 border-gray-300 dark:border-gray-600"></div>
              <h4 className="text-2xl font-bold text-gray-900 dark:text-gray-200">Android</h4>
              <p className="text-gray-600 dark:text-gray-400">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <h3 className="text-purple-600 text-lg font-bold">02</h3>
              </div>
              <div className="flex-grow border-b-2 border-gray-300 dark:border-gray-600"></div>
              <h4 className="text-2xl font-bold text-gray-900 dark:text-gray-200">Flutter</h4>
              <p className="text-gray-600 dark:text-gray-400">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <h3 className="text-purple-600 text-lg font-bold">03</h3>
              </div>
              <div className="flex-grow border-b-2 border-gray-300 dark:border-gray-600"></div>
              <h4 className="text-2xl font-bold text-gray-900 dark:text-gray-200">Codebase</h4>
              <p className="text-gray-600 dark:text-gray-400">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <h3 className="text-purple-600 text-lg font-bold">04</h3>
              </div>
              <div className="flex-grow border-b-2 border-gray-300 dark:border-gray-600"></div>
              <h4 className="text-2xl font-bold text-gray-900 dark:text-gray-200">Kotlin</h4>
              <p className="text-gray-600 dark:text-gray-400">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 px-20 bg-gradient-to-b from-white to-blue-100 dark:from-gray-900 dark:to-gray-800">
        <div className="container mx-auto text-left">
          <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 rounded-full px-4 py-2 mb-4 text-gray-700 dark:text-gray-300">
            <span className="text-blue-500 mr-2">•</span>
            <span>Services we offer</span>
            <span className="text-blue-500 ml-2">•</span>
          </div>

          <h2 className="text-4xl font-bold text-gray-900 dark:text-gray-200 mb-6">
            Our <span className="text-purple-600">Process</span>
          </h2>
          <p className="text-gray-600 dark:text-gray-400 text-left mb-8">
            Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam.
          </p>

          <div className="relative">
            <div className="absolute inset-0 top-[50px] w-[75%] border-t border-gray-300 dark:border-gray-600 mx-auto"></div>

            <div className="flex justify-between relative z-10">
              <div className="text-center w-1/4">
                <div className="bg-blue-600 text-white w-20 h-20 rounded-full flex items-center justify-center mx-auto mb-4 relative">
                  <img
                    src="/Images/image 21.png"
                    alt="Requirements Gathering Icon"
                    className="w-10 h-10"
                  />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
                  Requirements Gathering
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mt-2 max-w-[200px] mx-auto">
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam.
                </p>
              </div>

              <div className="text-center w-1/4">
                <div className="bg-blue-600 text-white w-20 h-20 rounded-full flex items-center justify-center mx-auto mb-4 relative">
                  <img
                    src="/Images/image 9.png"
                    alt="Development Icon"
                    className="w-10 h-10"
                  />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
                  Development
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mt-2 max-w-[200px] mx-auto">
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam.
                </p>
              </div>

              <div className="text-center w-1/4">
                <div className="bg-blue-600 text-white w-20 h-20 rounded-full flex items-center justify-center mx-auto mb-4 relative">
                  <img
                    src="/Images/image 22.png"
                    alt="Deployment Icon"
                    className="w-10 h-10"
                  />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
                  Deployment
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mt-2 max-w-[200px] mx-auto">
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam.
                </p>
              </div>

              <div className="text-center w-1/4">
                <div className="bg-blue-600 text-white w-20 h-20 rounded-full flex items-center justify-center mx-auto mb-4 relative">
                  <img
                    src="/Images/image 8.png"
                    alt="Maintenance Icon"
                    className="w-10 h-10"
                  />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
                  Maintenance
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mt-2 max-w-[200px] mx-auto">
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white dark:bg-gray-900">
  <div className="container mx-auto text-left">
    <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 rounded-full px-4 py-2 mb-4 text-gray-700 dark:text-gray-200">
      <span className="text-blue-500 mr-2">•</span>
      <span>Our Growth</span>
      <span className="text-blue-500 ml-2">•</span>
    </div>

    <h2 className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-6">
      Our Results <span className="text-purple-600">in numbers</span>
    </h2>
    <p className="text-gray-600 dark:text-gray-400 mb-12 max-w-2xl">
      Lorem ipsum dolor sit amet consectetur adipiscing elit mattis sit phasellus mollis sit aliquam sit nullam.
    </p>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div className="bg-blue-600 text-white p-6 rounded-[15px] shadow-md row-span-2 flex flex-col justify-center items-left">
        <img
          src="/Images/image 28.png"
          alt="Company Growth"
          className="w-16 h-16 mb-0"
        />
        <p className="text-xl font-semibold">Company Growth</p>
        <h3 className="text-5xl font-bold">240%</h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 29.png"
          alt="Customer Satisfaction"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>Customer Satisfaction</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          99<span className="text-4xl text-gray-400">%</span>
        </h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 30.png"
          alt="App Downloads"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>App Downloads</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          32<span className="text-4xl text-gray-400">M</span>
        </h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 31.png"
          alt="Raised"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>Raised</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          96<span className="text-4xl text-gray-400">M</span>
        </h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 32.png"
          alt="Employees"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>Employees</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          125<span className="text-4xl text-gray-400">+</span>
        </h3>
      </div>
    </div>
  </div>
</section>

      <section className="py-20 px-20 bg-gradient-to-b from-white to-blue-100 dark:from-gray-900 dark:to-gray-800 relative mt-40">
        <div className="container mx-auto text-center">
          <img
            src="/Images/emojistar 1.png"
            alt="3D Star"
            className="absolute top-2.5 transform -translate-y-1/2 w-[200px] h-[200px] lg:w-[300px] lg:h-[300px] left-[-4px]"
          />

          <p
            className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-20 text-left-20 relative"
            style={{ top: "-60px" }}
          >
            Let’s create the next{" "}
            <span className="text-purple-600">big thing</span> together!
          </p>

          <div className="flex justify-center space-x-6 ">
            <button className="bg-blue-500 text-white py-3 px-8  w-40 rounded-full hover:bg-blue-600">
              Get started
            </button>
            <button className="bg-gray-200 text-gray-700 dark:bg-gray-800 dark:text-gray-200 py-3 px-8 w-40 rounded-full hover:bg-gray-300">
              Talk to sales
            </button>
          </div>

          <img
            src="/Images/helix2 1.png"
            alt="3D Spiral"
            className="absolute top-1/2 right-0 transform -translate-y-1/2 w-[200px] h-[200px] lg:w-[300px] lg:h-[300px]"
          />
        </div>
      </section>
    </>
  );
};

export default ServiceDetails;
