import React from "react";

const Home = () => {
  return (
    <div>
   
      
      <section className="bg-gradient-to-b from-indigo-100 via-purple-100 to-blue-100 dark:from-gray-800 dark:via-gray-900 dark:to-black py-10 md:py-16 lg:py-20 px-6 md:px-12 lg:px-20">
  <div className="container mx-auto flex flex-col lg:flex-row items-center">
    <div className="lg:w-1/2 text-center lg:text-left relative mb-10 lg:mb-0">
      <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 rounded-full px-4 py-2 mb-4 text-gray-700 dark:text-gray-300">
        <span className="text-blue-500 mr-2">•</span>
        <span>At Cognito Soft</span>
        <span className="text-blue-500 ml-2">•</span>
      </div>

      <h1 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 dark:text-white mb-4">
        Empowering Your Business with Cutting-Edge{" "}
        <span className="text-purple-600 dark:text-purple-400">Digital Solutions</span>
      </h1>
      <p className="text-lg sm:text-xl md:text-2xl text-gray-600 dark:text-gray-400 mb-6 font-sans">
        From Web Development to AI Integrations, We Deliver Customized Solutions to Drive Growth and Innovation.
      </p>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <button className="bg-blue-500 text-white py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-blue-600">
          Get Started
        </button>
        <button className="bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600">
          Talk to Us
        </button>
      </div>
    </div>

    <div className="lg:w-1/2 relative h-[300px] sm:h-[400px] md:h-[500px]">
      <img
        src="/Images/torus 1.png"
        alt="Torus"
        className="w-[100px] sm:w-[150px] md:w-[200px] h-[100px] sm:h-[150px] md:h-[200px] absolute top-0 left-[30%] transform -translate-x-1/2"
      />
      <img
        src="/Images/cylinder 1.png"
        alt="Cylinder"
        className="w-[80px] sm:w-[100px] md:w-[150px] h-[80px] sm:h-[100px] md:h-[150px] absolute top-[40%] right-[10%] transform -translate-y-1/2"
      />
      <img
        src="/Images/cube-helix 1.png"
        alt="Cube Helix"
        className="w-[90px] sm:w-[120px] md:w-[180px] h-[90px] sm:h-[120px] md:h-[180px] absolute bottom-0 right-[30%] transform translate-y-[20px] md:translate-y-[50px]"
      />
    </div>
  </div>
</section>


   
     
      <section className="py-8 px-4 sm:px-10 md:px-16 lg:px-20 bg-white dark:bg-gray-900">
  <div className="container mx-auto text-center">
    <p className="text-black dark:text-white text-lg sm:text-xl mb-4 font-sans">
      <b>
        Trusted by <span className="text-purple-800 dark:text-purple-400">10,000+ companies</span> around
        the world
      </b>
    </p>
    <div className="flex flex-wrap justify-center gap-4 sm:gap-6 lg:gap-8 mt-6">
      <img
        src="/Images/Google.png"
        alt="Google"
        className="w-20 h-auto sm:w-24 md:w-28"
      />
      <img
        src="/Images/Facebook.png"
        alt="Facebook"
        className="w-20 h-auto sm:w-24 md:w-28"
      />
      <img
        src="/Images/YouTubelogo.png"
        alt="YouTube"
        className="w-20 h-auto sm:w-24 md:w-28"
      />
      <img
        src="/Images/Pinterest.png"
        alt="Pinterest"
        className="w-20 h-auto sm:w-24 md:w-28"
      />
      <img
        src="/Images/Twitch.png"
        alt="Twitch"
        className="w-20 h-auto sm:w-24 md:w-28"
      />
      <img
        src="/Images/Webflow.png"
        alt="Webflow"
        className="w-20 h-auto sm:w-24 md:w-28"
      />
    </div>
  </div>
</section>


   

      <section className="py-16 bg-gradient-to-b from-white to-indigo-50 dark:from-gray-800 dark:to-gray-900 px-6 lg:px-12">
  <div className="container mx-auto flex flex-col lg:flex-row items-center">
    <div className="lg:w-1/2 text-left">
      <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 rounded-full px-4 py-2 mb-4 text-gray-700 dark:text-gray-300">
        <span className="text-blue-500 mr-2">•</span>
        <span>Our Values</span>
        <span className="text-blue-500 ml-2">•</span>
      </div>

      <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-4">
        Who <span className="text-purple-600 dark:text-purple-400">we Are</span>
      </h2>
      <p className="text-gray-600 dark:text-gray-400 max-w-md mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam.
      </p>

      <div className="relative flex items-center justify-between max-w-md mb-10">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-4/5 border-t border-white-500"></div>
        </div>

        {['Innovation', 'Collaboration', 'Result Oriented', 'Sustainability'].map((value, index) => (
          <div key={index} className="relative  flex flex-col items-center">
            <span className="block w-4 h-4 bg-blue-500 rounded-full absolute top-[24px]"></span>
            <p className="mt-10 text-gray-700 dark:text-gray-300">{value}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 sm:space-y-0 space-y-4">
        <button className="bg-blue-500 text-white py-3 px-6 rounded-full hover:bg-blue-600">
          Get Started
        </button>
        <button className="bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 py-3 px-6 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600">
          Talk to Us
        </button>
      </div>
    </div>

    <div className="lg:w-1/2 flex justify-center lg:justify-end mt-8 lg:mt-0">
      <div className="relative w-full max-w-[473px] h-[500px] bg-white dark:bg-gray-800 rounded-[20px] shadow-md">
        <img
          src="/Images/Image Placeholder (15).png"
          alt="Project Title"
          className="w-full h-full rounded-lg object-cover"
        />
        <div className="absolute top-[50%] left-[-80px] w-[231px] h-[283px] bg-light-grayish-lavender dark:bg-gray-700 rounded-[80px]">
          <img
            src="/Images/Container (7).png"
            alt="Project Title"
            className="w-full h-full rounded-lg object-cover"
          />
        </div>
      </div>
    </div>
  </div>
</section>



     
<section className="py-16 bg-gradient-to-b from-white to-indigo-50 dark:from-gray-800 dark:to-gray-900 px-6 lg:px-12">
  <div className="container mx-auto">
    <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
      <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 rounded-full px-4 py-2 text-gray-700 dark:text-gray-200">
        <span className="text-blue-500 mr-2">•</span>
        <span>Services we offer</span>
        <span className="text-blue-500 ml-2">•</span>
      </div>

      <div className="flex items-center space-x-4 mt-4 lg:mt-0">
        <button className="w-[160px] h-[40px] flex justify-center items-center border border-gray-300 dark:border-gray-600 text-blue-500 dark:text-blue-400 space-x-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
          <span className="text-center">View all Services</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" className="w-5 h-5">
            <path
              style={{ fill: "#232326" }}
              d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z"
              data-name="Right"
            />
          </svg>
        </button>

        <button className="w-12 h-12 flex justify-center items-center border border-gray-300 dark:border-gray-600 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 text-gray-500 dark:text-gray-300"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <button className="w-12 h-12 flex justify-center items-center border border-gray-300 dark:border-gray-600 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 text-gray-500 dark:text-gray-300"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>

    <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2 text-left">
      What <span className="text-purple-600">we do</span>
    </h2>
    <p className="text-gray-600 dark:text-gray-400 text-left mb-8">
      Lorem ipsum dolor sit amet consectetur adipiscing elit mattis sit pharetra mollis sit aliquam sit nullam.
    </p>

    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
      {[
        {
          title: "Web Development",
          image: "/Images/image 4.png",
          description: "Lorem ipsum dolor sit amet consectetur tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitom.",
        },
        {
          title: "Cloud Services",
          image: "/Images/image 2.png",
          description: "Lorem ipsum dolor sit amet consectetur tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitom.",
        },
        {
          title: "Amazon Services",
          image: "/Images/image 3.png",
          description: "Lorem ipsum dolor sit amet consectetur tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitom.",
        },
      ].map((service, index) => (
        <div
          key={index}
          className="bg-white dark:bg-gray-800 p-6 border rounded-xl shadow-md border-gray-300 dark:border-gray-600"
        >
          <img src={service.image} alt={service.title} className="w-12 h-12 mb-4 mx-left" />
          <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">{service.title}</h3>
          <p className="text-gray-600 dark:text-gray-400 mt-2">{service.description}</p>
          <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
            Learn more →
          </button>
        </div>
      ))}
    </div>
  </div>
</section>





<section className="py-16 bg-white dark:bg-gray-900">
  <div className="container mx-auto text-left">
    <div className="inline-flex items-center bg-gray-100 dark:bg-gray-700 rounded-full px-4 py-2 mb-4 text-gray-700 dark:text-gray-200">
      <span className="text-blue-500 mr-2">•</span>
      <span>Our Growth</span>
      <span className="text-blue-500 ml-2">•</span>
    </div>

    <h2 className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-6">
      Our Results <span className="text-purple-600">in numbers</span>
    </h2>
    <p className="text-gray-600 dark:text-gray-400 mb-12 max-w-2xl">
      Lorem ipsum dolor sit amet consectetur adipiscing elit mattis sit phasellus mollis sit aliquam sit nullam.
    </p>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div className="bg-blue-600 text-white p-6 rounded-[15px] shadow-md row-span-2 flex flex-col justify-center items-left">
        <img
          src="/Images/image 28.png"
          alt="Company Growth"
          className="w-16 h-16 mb-0"
        />
        <p className="text-xl font-semibold">Company Growth</p>
        <h3 className="text-5xl font-bold">240%</h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 29.png"
          alt="Customer Satisfaction"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>Customer Satisfaction</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          99<span className="text-4xl text-gray-400">%</span>
        </h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 30.png"
          alt="App Downloads"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>App Downloads</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          32<span className="text-4xl text-gray-400">M</span>
        </h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 31.png"
          alt="Raised"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>Raised</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          96<span className="text-4xl text-gray-400">M</span>
        </h3>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-[15px] shadow-md flex flex-col justify-center items-center">
        <img
          src="/Images/image 32.png"
          alt="Employees"
          className="w-[65px] h-[65px] mb-4 dark:filter dark:invert"
        />
        <p className="text-gray-600 dark:text-white mt-2"><b>Employees</b></p>
        <h3 className="text-4xl font-bold text-gray-700 dark:text-white">
          125<span className="text-4xl text-gray-400">+</span>
        </h3>
      </div>
    </div>
  </div>
</section>




      <section className="py-16 bg-gradient-to-b from-white to-blue-100 dark:from-gray-900 dark:to-gray-800 relative mt-40">
  <div className="container mx-auto text-center relative">
    <img
      src="/Images/emojistar 1.png"
      alt="3D Star"
      className="absolute top-2.5 transform -translate-y-1/2 w-[200px] h-[200px] lg:w-[300px] lg:h-[300px] left-[-4px]"
    />

    <p className="text-3xl font-bold text-gray-800 dark:text-white mb-20 relative">
      Let’s create the next <span className="text-purple-600">big thing</span> together!
    </p>

    <div className="flex justify-center space-x-6">
      <button 
        className="bg-blue-500 text-white py-3 px-8 w-40 rounded-full hover:bg-blue-600 transition-colors duration-300"
        aria-label="Get started"
      >
        Get started
      </button>
      <button 
        className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white py-3 px-8 w-40 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
        aria-label="Talk to sales"
      >
        Talk to sales
      </button>
    </div>

    <img
      src="/Images/helix2 1.png"
      alt="3D Spiral"
      className="absolute top-1/2 right-0 transform -translate-y-1/2 w-[200px] h-[200px] lg:w-[300px] lg:h-[300px]"
    />
  </div>
</section>





    </div>
  );
};

export default Home;
