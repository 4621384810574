// import React from "react";

// const Footer = () => {
//   return (
//     <footer className="bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-400 py-8">
//       <div className="container mx-auto px-8">
//         <div className="flex justify-between flex-wrap space-y-8 md:space-y-0 md:space-x-0">
          
//           <div className="w-full md:w-1/4 mb-6">
//             <img
//               src="/Images/cognitosoft__1_-removebg-preview.png"
//               alt="Cognito Soft Logo"
//               className="w-[212px] h-[99px] mb-4"
//             />
//             <p className="text-sm">
//               Lorem ipsum dolor sit amet consectetur dolol drakgonil adipiscing
//               elit aliquam mauris
//             </p>
         
//             <div className="mt-4">
//               <input
//                 type="email"
//                 placeholder="Enter your email"
//                 className="w-full p-2 rounded-md text-gray-900"
//               />
//               <button className="mt-2 w-40 h-12 bg-blue-500 text-white rounded-full flex items-center justify-center hover:bg-blue-600">
//                 Subscribe
//                 <i className="fas fa-paper-plane"></i>
//               </button>
//             </div>
//           </div>

          
//           <div className="w-full md:w-1/6 mb-6 md:mb-0">
//             <h3 className="text-lg font-bold mb-4">Menu</h3>
//             <ul>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Home
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Pricing
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Case Studies
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Features
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Downloads
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Updates
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Changelog
//                 </a>
//               </li>
//             </ul>
//           </div>

//           <div className="w-full md:w-1/4 mb-6 md:mb-0">
//             <h3 className="text-lg font-bold mb-4">Company</h3>
//             <ul>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   About
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Contact Us
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Careers
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Culture
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Help Center
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Support
//                 </a>
//               </li>
//               <li className="mb-2">
//                 <a href="/" className="hover:text-blue-500">
//                   Legal
//                 </a>
//               </li>
//             </ul>
//           </div>

         
//           <div className="w-full md:w-1/4 mb-6 md:mb-0">
//             <h3 className="text-lg font-bold mb-4">Follow on Instagram</h3>
//             <div className="grid grid-cols-2 gap-4">
//               <img
//                 src="/Images/Image Placeholder.png"
//                 alt="Instagram 1"
//                 className="w-full h-auto rounded-md"
//               />
//               <img
//                 src="/Images/Image Placeholder (1).png"
//                 alt="Instagram 2"
//                 className="w-full h-auto rounded-md"
//               />
//               <img
//                 src="/Images/Image Placeholder (2).png"
//                 alt="Instagram 3"
//                 className="w-full h-auto rounded-md"
//               />
//               <img
//                 src="/Images/Image Placeholder (3).png"
//                 alt="Instagram 4"
//                 className="w-full h-auto rounded-md"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="mt-8 flex flex-col md:flex-row justify-between items-center border-t pt-6">
//           <p className="text-sm text-gray-600 dark:text-gray-400">
//             Copyright © 2024 Cognito Soft | All Rights Reserved
//           </p>
//           <div className="mt-4 md:mt-0 flex space-x-4">
           
//             <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
//               <img
//                 src="/Images/facebookfooter.png"
//                 alt="Facebook"
//                 className="w-8 h-8 hover:opacity-80 transition-opacity"
//               />
//             </a>
//             <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
//               <img
//                 src="/Images/twitter.png"
//                 alt="Twitter"
//                 className="w-8 h-8 hover:opacity-80 transition-opacity"
//               />
//             </a>
//             <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
//               <img
//                 src="/Images/instagram.png"
//                 alt="Instagram"
//                 className="w-8 h-8 hover:opacity-80 transition-opacity"
//               />
//             </a>
//             <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
//               <img
//                 src="/Images/linkedin.png"
//                 alt="LinkedIn"
//                 className="w-8 h-8 hover:opacity-80 transition-opacity"
//               />
//             </a>
//             <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
//               <img
//                 src="/Images/youtube.png"
//                 alt="YouTube"
//                 className="w-8 h-8 hover:opacity-80 transition-opacity"
//               />
//             </a>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;







import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-400 py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row justify-between flex-wrap space-y-8 md:space-y-0 md:space-x-8">
          
       
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <img
              src="/Images/cognitosoft__1_-removebg-preview.png"
              alt="Cognito Soft Logo"
              className="w-[180px] h-auto mb-4"
            />
            <p className="text-sm">
              Lorem ipsum dolor sit amet consectetur dolol drakgonil adipiscing
              elit aliquam mauris
            </p>
            <div className="mt-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full p-2 rounded-md text-gray-900"
              />
              <button className="mt-2 w-full md:w-40 h-12 bg-blue-500 text-white rounded-full flex items-center justify-center hover:bg-blue-600">
                Subscribe
                <i className="fas fa-paper-plane ml-2"></i>
              </button>
            </div>
          </div>

       
          <div className="w-full md:w-1/6 mb-6 md:mb-0">
            <h3 className="text-lg font-bold mb-4">Menu</h3>
            <ul className="space-y-2">
              {["Home", "Pricing", "Case Studies", "Features", "Downloads", "Updates", "Changelog"].map((menuItem) => (
                <li key={menuItem}>
                  <a href="/" className="hover:text-blue-500">
                    {menuItem}
                  </a>
                </li>
              ))}
            </ul>
          </div>

      
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-bold mb-4">Company</h3>
            <ul className="space-y-2">
              {["About", "Contact Us", "Careers", "Culture", "Help Center", "Support", "Legal"].map((companyItem) => (
                <li key={companyItem}>
                  <a href="/" className="hover:text-blue-500">
                    {companyItem}
                  </a>
                </li>
              ))}
            </ul>
          </div>

         
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-bold mb-4">Follow on Instagram</h3>
            <div className="grid grid-cols-2 gap-4">
              {[1, 2, 3, 4].map((imgNum) => (
                <img
                  key={imgNum}
                  src={`/Images/Image Placeholder (${imgNum - 1}).png`}
                  alt={`Instagram ${imgNum}`}
                  className="w-full h-auto rounded-md"
                />
              ))}
            </div>
          </div>
        </div>

        <div className="mt-8 flex flex-col md:flex-row justify-between items-center border-t pt-6">
          <p className="text-sm text-center md:text-left text-gray-600 dark:text-gray-400">
            © 2024 Cognito Soft | All Rights Reserved
          </p>
          <div className="mt-4 md:mt-0 flex space-x-4">
            {["facebook", "twitter", "instagram", "linkedin", "youtube"].map((platform) => (
              <a key={platform} href={`https://www.${platform}.com`} target="_blank" rel="noopener noreferrer">
                <img
                  src={`/Images/${platform}.png`}
                  alt={platform}
                  className="w-8 h-8 hover:opacity-80 transition-opacity"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
